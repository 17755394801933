import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import { Container, Nav, Navbar as BootstrapNavbar } from "react-bootstrap";
import styles from "@src/components/salesforce/Navbar/Navbar.module.scss";

const Navbar = () => {
  const location = useLocation();
  return (
    <BootstrapNavbar id="navbar" bg="light" expand="sm">
      <Container>
        <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
        <BootstrapNavbar.Collapse id="basic-navbar-nav">
          <Nav className={styles["styled-nav"]}>
            <Nav.Link as={NavLink} to={`/private/salesforce/orders${location.search}`}>
              Orders
            </Nav.Link>
            <Nav.Link as={NavLink} to={`/private/salesforce/manufacturers_data${location.search}`}>
              Manufacturer's Data
            </Nav.Link>
            <Nav.Link as={NavLink} to={`/private/salesforce/users${location.search}`}>
              Users
            </Nav.Link>
            <Nav.Link as={NavLink} to={`/private/salesforce/devices${location.search}`}>
              Devices
            </Nav.Link>
          </Nav>
        </BootstrapNavbar.Collapse>
      </Container>
    </BootstrapNavbar>
  );
};

export default Navbar;
