import React, { useEffect } from "react";

import { Loader } from "@src/components";
import { useLocation } from "react-router-dom";

const CallbackPage = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.opener) {
      const stringParams = location.search.substring(1);
      const params = new URLSearchParams(stringParams);
      const code = params.get("code");
      window.opener.postMessage({ code, messageType: "authorization_code" }, window.origin);
    }
  }, []);

  return (
    <div>
      <Loader />
    </div>
  );
};

export default CallbackPage;
