import { ReactNode } from "react";

export interface TableSettings {
  rows: Row[];
  columns: Column[];
  initialSortingState?: TableSortingState;
  onRefresh: RefreshHandler;
  onApply: IncomingApplyHandler;
  onCellEdit?: EditHandler;
  getRowId: RowIdGetter;
}

export type UseTableReturnType = [state: TableState, handlers: TableHandlers];

export enum TableSortingDirection {
  ASC = "ASC",
  DSC = "DSC",
}

export interface TableSortingState {
  direction?: TableSortingDirection;
  columnKey?: string;
}

export interface TableHandlers {
  onRefresh: RefreshHandler;
  onApply: ExportedApplyHandler;
  onReset: ResetHandler;
  onEdit: EditHandler;
  onSort: SortHandler;
  getRowId: RowIdGetter;
}

export interface TableState {
  rows: Row[];
  originalRows: Row[];
  columns: Column[];
  sortingState: TableSortingState;
}

export type RefreshHandler = () => Promise<unknown>;
export type IncomingApplyHandler = (rows: Row[]) => Promise<unknown>;
export type ExportedApplyHandler = () => Promise<unknown>;
export type ResetHandler = () => void;
export type EditHandler = (columnKey: string, rowIndex: number, value: CellValue) => void;
export type SortHandler = (columnKey: string) => void;
export type RowIdGetter = (row: Row) => string;

export type Row = Record<string, CellValue>;

// note: smuggled assumption - only non-editable values are possible to map
export type Column = ReadOnlyColumn | EditableColumn;

export interface ReadOnlyColumn {
  key: string;
  label: ReactNode;
  type: ColumnType.NonEditable;
}

export type EditableColumn = TextColumn | NumberColumn | SelectColumn;

interface TextColumn {
  key: string;
  label: ReactNode;
  type: ColumnType.Text;
}

interface NumberColumn {
  key: string;
  label: ReactNode;
  type: ColumnType.Number;
  scope: [number, number];
  step: number;
}

export interface SelectColumn {
  key: string;
  label: ReactNode;
  type: ColumnType.Select;
  options: SelectColumnOption[];
}

export interface SelectColumnOption {
  label: string;
  value: NonMappableCellValue;
}

export enum ColumnType {
  // @TODO: Add Date, Time, DateTime types
  NonEditable = "non-editable",
  Number = "number",
  Text = "text",
  Select = "select",
}

// note: smuggled assumption - only non-editable values are possible to map
export type CellValue = NonMappableCellValue | MappedCellValue<NonMappableCellValue>;

export type NonMappableCellValue = string | number | boolean | Date;

export type MappedCellValue<TOriginal> = {
  displayValue: string;
  originalValue: TOriginal;
};
