import React, { HTMLProps } from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";

import styles from "@src/components/common/RetriableWrapper/RetriableWrapper.module.scss";

const RetryButton: React.FC<HTMLProps<HTMLButtonElement>> = ({ className, ...rest }) => {
  return (
    <button {...rest} className={classnames(styles.button, className)} type="button">
      <FontAwesomeIcon icon={faArrowsRotate} />
      RETRY
    </button>
  );
};

export default RetryButton;
