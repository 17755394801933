import { ApiClientInterface, UserItemViewValue, UserItemModelValue } from "@src/services";

class UsersApiClient {
  static apiClient: ApiClientInterface;

  static setup(apiClient: ApiClientInterface) {
    UsersApiClient.apiClient = apiClient;
  }

  static async getAll(salesforceId: string): Promise<UserItemModelValue[]> {
    if (!salesforceId) {
      throw new Error("Salesforce account id not present in parameters");
    }
    const response = await UsersApiClient.apiClient.get<UserItemModelValue[]>(
      `/api/v1/admin/customers/${salesforceId}/users`
    );
    return response.data.data;
  }

  static async applyChanges(salesforceId: string, items: UserItemModelValue[]): Promise<UserItemModelValue[]> {
    if (!salesforceId) {
      throw new Error("Salesforce account id not present in parameters");
    }
    const response = await UsersApiClient.apiClient.patch<UserItemModelValue[], UserItemModelValue[]>(
      `/api/v1/admin/customers/${salesforceId}/users`,
      items
    );
    return response.data.data;
  }

  static modelValuesToViewValues(modelValues: UserItemModelValue[]): UserItemViewValue[] {
    return modelValues as UserItemViewValue[];
  }

  static viewValuesToModelValues(modelValues: UserItemViewValue[]): UserItemModelValue[] {
    return modelValues as UserItemModelValue[];
  }
}

export default UsersApiClient;
