import { useCallback, useEffect } from "react";

import { UserItemViewValue, UsersApiClient } from "@src/services";
import { ExecutorFactory, RequestState, useApiClient, useAsync } from "@src/hooks";

// eslint-disable-next-line
interface AsyncAction<P extends any[], T> {
  state: RequestState<T>;
  execute: ExecutorFactory<P, T>;
}

function useUsers(
  salesforceId: string
): [AsyncAction<[], UserItemViewValue[]>, AsyncAction<[UserItemViewValue[]], void>] {
  const apiClient = useApiClient();

  useEffect(() => {
    UsersApiClient.setup(apiClient);
  }, [apiClient]);

  const handleGetUsers = useCallback(async () => {
    const modelValues = await UsersApiClient.getAll(salesforceId);
    return UsersApiClient.modelValuesToViewValues(modelValues);
  }, [salesforceId]);

  const [user, getUsers] = useAsync<[], UserItemViewValue[]>(handleGetUsers);

  const handleApplyUsers = useCallback(
    async (orderItems: UserItemViewValue[]) => {
      const modelValues = UsersApiClient.viewValuesToModelValues(orderItems);
      await UsersApiClient.applyChanges(salesforceId, modelValues);
      await getUsers();
    },
    [salesforceId]
  );

  const [applyingState, applyUsers] = useAsync<[UserItemViewValue[]], void>(handleApplyUsers);

  useEffect(() => {
    getUsers();
  }, []);

  return [
    { state: user, execute: getUsers },
    { state: applyingState, execute: applyUsers },
  ];
}

export default useUsers;
