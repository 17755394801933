// This is just a wrapper for regular localstorage used for testing purposes
// we can mock LocalStorageService and assume it works, since it does not have any logic

class LocalStorageService {
  static getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  static setItem(key: string, value: string): void {
    return localStorage.setItem(key, value);
  }

  static removeItem(key: string): void {
    return localStorage.removeItem(key);
  }
}

export default LocalStorageService;
