import React, { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { Orders } from "@src/components";

import { Column, ColumnType, RequestStatus, TableSortingDirection, TableSortingState, useOrders } from "@src/hooks";

import { OrderItemViewValue, OrderType, RmaType } from "@src/services";

const baseColumns: Column[] = [
  {
    key: "salesforce_id",
    label: "Customer SF account ID number",
    type: ColumnType.NonEditable,
  },
  {
    label: "order_type",
    key: "order_type",
    type: ColumnType.Select,
    options: [
      { label: "null", value: null },
      { label: OrderType.Exchange, value: OrderType.Exchange },
      { label: OrderType.Sales, value: OrderType.Sales },
      { label: OrderType.Upgrade, value: OrderType.Upgrade },
      { label: OrderType.DeviceUser, value: OrderType.DeviceUser },
    ],
  },
  {
    label: "rma_type",
    key: "rma_type",
    type: ColumnType.Select,
    options: [
      { label: "null", value: null },
      { label: RmaType.Exchange, value: RmaType.Exchange },
      { label: RmaType.Upgrade, value: RmaType.Upgrade },
      { label: RmaType.Loss_Damage, value: RmaType.Loss_Damage },
      { label: RmaType.In_Trial_Upgrade, value: RmaType.In_Trial_Upgrade },
      { label: RmaType.Out_Of_Warranty, value: RmaType.Out_Of_Warranty },
    ],
  },
  {
    label: "processed",
    key: "processed",
    type: ColumnType.Select,
    options: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
  },
  {
    label: "Product Serial Number",
    key: "product_serial_number",
    type: ColumnType.NonEditable,
  },
  {
    label: "Product Code",
    key: "product_code",
    type: ColumnType.NonEditable,
  },
  {
    label: "Order Number",
    key: "order_number",
    type: ColumnType.NonEditable,
  },
  {
    label: "Creation Date",
    key: "created_at",
    type: ColumnType.NonEditable,
  },
  {
    label: "Last Update Date",
    key: "updated_at",
    type: ColumnType.NonEditable,
  },
];

const initialSortingState: TableSortingState = {
  columnKey: "created_at",
  direction: TableSortingDirection.ASC,
};

const OrdersPage = () => {
  const [searchParams] = useSearchParams();
  const [fetching, applying] = useOrders(searchParams.get("accountId"));

  const getOrdersItemId = useCallback((item: OrderItemViewValue) => item.id.toString(), []);

  const columnsConfig = useMemo(() => {
    if (fetching.state.status !== RequestStatus.success) {
      return baseColumns;
    }
    const originalOrder: Column = {
      label: "original_order_number",
      key: "original_order_number",
      type: ColumnType.Select,
      options: [
        ...fetching.state.value.map((order) => {
          if (order.order_number === null) {
            return {
              value: null,
              label: "null",
            };
          }
          return { value: order.order_number, label: order.order_number };
        }),
      ],
    };

    return [...baseColumns.slice(0, 3), originalOrder, ...baseColumns.slice(3)];
  }, [fetching.state]);

  return (
    <Orders
      columns={columnsConfig}
      initialSortingState={initialSortingState}
      gettingOrdersState={fetching.state}
      applyingOrdersState={applying.state}
      onApply={applying.execute}
      onRefresh={fetching.execute}
      getItemId={getOrdersItemId}
    />
  );
};

export default OrdersPage;
