import React from "react";

import { Navbar } from "@src/components";

const SalesforcePagesLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 py-3">{children}</div>
        </div>
      </div>
    </>
  );
};

export default SalesforcePagesLayout;
