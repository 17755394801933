import React from "react";
import { AxiosError } from "axios";

import { ErrorMessage, Loader, RetriableWrapper, Table } from "@src/components";

import { OrderItemViewValue } from "@src/services";

import {
  Column,
  IncomingApplyHandler,
  RefreshHandler,
  RequestState,
  RequestStatus,
  TableSortingState,
} from "@src/hooks";

interface OrdersProps {
  columns: Column[];
  initialSortingState: TableSortingState;
  gettingOrdersState: RequestState<OrderItemViewValue[]>;
  applyingOrdersState: RequestState<void>;
  onApply: IncomingApplyHandler;
  onRefresh: RefreshHandler;
  getItemId: (item: OrderItemViewValue) => string;
}

const Orders: React.FC<OrdersProps> = ({
  columns,
  initialSortingState,
  gettingOrdersState,
  applyingOrdersState,
  onApply,
  onRefresh,
  getItemId,
}) => {
  if (gettingOrdersState.status === RequestStatus.success && gettingOrdersState.value.length === 0) {
    return (
      <RetriableWrapper onRetry={onRefresh}>
        <div className="text-center" data-testid="empty-list-message">
          Empty List
        </div>
      </RetriableWrapper>
    );
  }

  if (
    gettingOrdersState.status === RequestStatus.pending ||
    gettingOrdersState.status === RequestStatus.idle ||
    applyingOrdersState.status === RequestStatus.pending
  ) {
    return <Loader />;
  }

  if (
    gettingOrdersState.status === RequestStatus.error &&
    (gettingOrdersState.error instanceof AxiosError || gettingOrdersState.error instanceof Error)
  ) {
    return (
      <RetriableWrapper onRetry={onRefresh}>
        <ErrorMessage message={`Error: ${gettingOrdersState.error.message}`} />
      </RetriableWrapper>
    );
  }

  if (gettingOrdersState.status === RequestStatus.error) {
    return (
      <RetriableWrapper onRetry={onRefresh}>
        <ErrorMessage message={`Error: ${gettingOrdersState.error.toString()}`} />
      </RetriableWrapper>
    );
  }

  return (
    <Table
      id="orders"
      columns={columns}
      rows={gettingOrdersState.value}
      initialSortingState={initialSortingState}
      onRefresh={onRefresh}
      onApply={onApply}
      getRowId={getItemId}
    />
  );
};

export default Orders;
