import { EargoGeneration, EargoGenerationHumanFriendly } from "@src/services/EargoGenerationService/types";

class EargoGenerationService {
  static humanFriendlyValueMap: Record<EargoGeneration, EargoGenerationHumanFriendly> = {
    [EargoGeneration.FIVE]: EargoGenerationHumanFriendly.FIVE,
    [EargoGeneration.SIX]: EargoGenerationHumanFriendly.SIX,
    [EargoGeneration.SEVEN]: EargoGenerationHumanFriendly.SEVEN,
  };
  static originalGenerationValueMap: Record<EargoGenerationHumanFriendly, EargoGeneration> = {
    [EargoGenerationHumanFriendly.FIVE]: EargoGeneration.FIVE,
    [EargoGenerationHumanFriendly.SIX]: EargoGeneration.SIX,
    [EargoGenerationHumanFriendly.SEVEN]: EargoGeneration.SEVEN,
  };
}

export default EargoGenerationService;
