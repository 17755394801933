import { useState, useCallback } from "react";

import { RequestState, RequestStatus, UseAsyncReturnType, ExecutorFactory } from "@src/hooks/common/useAsync.types";

const defaultOptions: RequestState<null> = {
  status: RequestStatus.idle,
  value: null,
  error: null,
};

// eslint-disable-next-line
const useAsync = <P extends any[] | [], T>(
  asyncFunction: ExecutorFactory<P, T>,
  initialState?: Partial<RequestState<T>>
): UseAsyncReturnType<P, T> => {
  const [requestState, setRequestState] = useState<RequestState<T>>(() => ({ ...defaultOptions, ...initialState }));

  const execute = useCallback(
    async (...params: P) => {
      setRequestState((prev) => ({
        ...prev,
        status: RequestStatus.pending,
        error: null,
      }));

      try {
        const response = await asyncFunction(...params);
        setRequestState((prev) => ({
          ...prev,
          value: response,
          status: RequestStatus.success,
        }));
        return response;
      } catch (error) {
        setRequestState((prev) => ({
          ...prev,
          error,
          status: RequestStatus.error,
        }));
      }
    },
    [asyncFunction]
  );

  return [requestState, execute];
};

export default useAsync;
