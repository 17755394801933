import React, { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { Column, ColumnType, RequestStatus, TableSortingDirection, TableSortingState, useDevices } from "@src/hooks";

import { DeviceItemViewValue, DeviceStatusType } from "@src/services";

import { Devices, ErrorMessage, Loader, RetriableWrapper } from "@src/components";
import { AxiosError } from "axios";

const baseDevicesColumns: Column[] = [
  {
    label: "Id",
    key: "id",
    type: ColumnType.NonEditable,
  },
  {
    label: "Serial Number",
    key: "serial_number",
    type: ColumnType.NonEditable,
  },
  {
    label: "System SN",
    key: "system_serial_number",
    type: ColumnType.Text
  },
  {
    label: "Device Type",
    key: "device_type",
    type: ColumnType.NonEditable,
  },
  {
    label: "Status",
    key: "status",
    type: ColumnType.Select,
    options: [
      { label: DeviceStatusType.Shipped, value: DeviceStatusType.Shipped },
      { label: DeviceStatusType.Delivered, value: DeviceStatusType.Delivered },
      { label: DeviceStatusType.Deactivated, value: DeviceStatusType.Deactivated },
    ],
  },
  {
    label: "Firmware Version",
    key: "firmware_version",
    type: ColumnType.NonEditable,
  },
  {
    label: "Generation",
    key: "generation",
    type: ColumnType.NonEditable
  },
  {
    label: "Device BLE Name",
    key: "ble_name",
    type: ColumnType.NonEditable,
  },
  {
    label: "Device Name",
    key: "name",
    type: ColumnType.Text,
  },
  {
    label: "Device Origin",
    key: "device_origin",
    type: ColumnType.NonEditable,
  },
  {
    label: "Receiver Calibration",
    key: "receiver_calibration",
    type: ColumnType.NonEditable,
  },
  {
    label: "Is Semi Deactivated",
    key: "is_semi_deactivated",
    type: ColumnType.NonEditable,
  },
  {
    label: "Creation Date",
    key: "created_at",
    type: ColumnType.NonEditable,
  },
  {
    label: "Last Update Date",
    key: "updated_at",
    type: ColumnType.NonEditable,
  },
];

const initialSortingState: TableSortingState = {
  columnKey: "generation",
  direction: TableSortingDirection.ASC,
};

const DevicesPage = () => {
  const [searchParams] = useSearchParams();
  const [fetching, applying] = useDevices(searchParams.get("accountId"));

  const getDevicesItemId = useCallback((item: DeviceItemViewValue) => item.id.toString(), []);

  if (fetching.state.status === RequestStatus.success && fetching.state.value.length === 0) {
    return (
      <RetriableWrapper onRetry={fetching.execute}>
        <div data-testid="empty-list-message">Empty List</div>
      </RetriableWrapper>
    );
  }

  if (
    fetching.state.status === RequestStatus.pending ||
    fetching.state.status === RequestStatus.idle ||
    applying.state.status === RequestStatus.pending
  ) {
    return <Loader />;
  }

  if (
    fetching.state.status === RequestStatus.error &&
    (fetching.state.error instanceof AxiosError || fetching.state.error instanceof Error)
  ) {
    return (
      <RetriableWrapper onRetry={fetching.execute}>
        <ErrorMessage message={`Error: ${fetching.state.error.message}`} />
      </RetriableWrapper>
    );
  }

  if (fetching.state.status === RequestStatus.error) {
    return (
      <RetriableWrapper onRetry={fetching.execute}>
        <ErrorMessage message={`Error: ${fetching.state.error.toString()}`} />
      </RetriableWrapper>
    );
  }

  return (
    <Devices
      columns={baseDevicesColumns}
      initialSortingState={initialSortingState}
      gettingDevicesState={fetching.state}
      applyingDevicesState={applying.state}
      onApply={applying.execute}
      onRefresh={fetching.execute}
      getItemId={getDevicesItemId}
    />
  );
};

export default DevicesPage;
