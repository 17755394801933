import React from "react";
import { Route, Routes } from "react-router-dom";

import { MainSupportPage } from "@src/pages";

const SupportRoutes = () => {
  return (
    <Routes>
      <Route index element={<MainSupportPage />} />
      <Route path="main_support" element={<MainSupportPage />} />
    </Routes>
  );
};

export default SupportRoutes;
