import { AxiosRequestConfig, AxiosResponse } from "axios";

export enum ApiResponseStatus {
  success = "success",
  error = "error",
}

export interface ApiResponse<T> {
  data: T;
  status: ApiResponseStatus;
}

export interface ApiClientInterface {
  get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<ApiResponse<T>>>;
  post<D, T>(url: string, data: D, config?: AxiosRequestConfig): Promise<AxiosResponse<ApiResponse<T>>>;
  put<D, T>(url: string, data: D, config?: AxiosRequestConfig): Promise<AxiosResponse<ApiResponse<T>>>;
  patch<D, T>(url: string, data: D, config?: AxiosRequestConfig): Promise<AxiosResponse<ApiResponse<T>>>;
}
