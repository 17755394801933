import React, { ReactNode } from "react";
import { AxiosError } from "axios";

import { ErrorMessage, Loader } from "@src/components";

import styles from "@src/components/login/Login.module.scss";

interface LoginProps {
  handleLogin: () => Promise<void>;
  disabled: boolean;
  loggingInState: { loading: boolean; success: boolean; error: AxiosError | Error };
}

const Login: React.FC<LoginProps> = ({ handleLogin, disabled, loggingInState }) => {
  const renderLoaderIfExists = () => {
    if (!loggingInState.loading) {
      return null;
    }
    return <Loader />;
  };
  const renderErrorIfExists = (): ReactNode | null => {
    if (!loggingInState.error) {
      return null;
    }
    if (loggingInState.error instanceof Error && loggingInState.error.message === "invalid_session") {
      return <ErrorMessage message="Error: Log in expired or invalid. Please log in again." />;
    }
    if (loggingInState.error instanceof AxiosError && loggingInState.error.response.status === 401) {
      return <ErrorMessage message="Error: Your SF account is not authorized. Please contact SF support." />;
    }
    return <ErrorMessage message="Unexpected error. Please contact SF support." />;
  };
  return (
    <div className={styles.wrapper}>
      <button disabled={disabled} className={styles["login-button"]} onClick={handleLogin}>
        Log in with SF
      </button>
      {renderLoaderIfExists()}
      {renderErrorIfExists()}
    </div>
  );
};

export default Login;
