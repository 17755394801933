import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useAuth } from "@src/hooks";

interface Props {
  children: React.ReactNode;
}

const AuthGuard: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const auth = useAuth();

  if (!auth.isLogged) {
    const redirectUrl = encodeURI(location.pathname + location.search);
    const searchParams = new URLSearchParams(location.search.split("?").join(""));
    searchParams.set("redirect", redirectUrl);
    return <Navigate to={`/oauth/login?${searchParams.toString()}`} replace />;
  }
  return <>{children}</>;
};
export default AuthGuard;
