import React, { useMemo } from "react";
import Modal from "react-modal";

import { Button } from "@src/components";
import { ButtonType } from "@src/components/common/Button/Button";

import { DeviceItemViewValue } from "@src/services";

import styles from "@src/components/salesforce/Devices/PopulateDeviceTypeChangeConfirmationModal.module.scss";

interface ModalProps {
  device: DeviceItemViewValue;
  isOpen: boolean;
  onConfirm: () => void;
  onDeny: () => void;
}

const PopulateDeviceTypeChangeConfirmationModal: React.FC<ModalProps> = ({ device, isOpen, onConfirm, onDeny }) => {
  const deviceType = useMemo(() => device?.device_type?.displayValue, [device]);
  const systemSerialNo = useMemo(() => device?.system_serial_number, [device]);

  return (
    <Modal
      className={styles.modal}
      overlayClassName={styles.overlay}
      isOpen={isOpen}
      contentLabel="Device Modal"
      testId="populate-device-type-change-confirmation-prompt"
    >
      <h5>
        Do you want to DEACTIVATE the other {deviceType}s belonging to this device's system (SN={systemSerialNo})?
      </h5>
      <div className={styles["buttons-wrapper"]}>
        <Button text="Confirm" type={ButtonType.Primary} onClick={onConfirm} data-testid="button-confirm" />
        <Button text="Deny" type={ButtonType.Classic} onClick={onDeny} data-testid="button-cancel" />
      </div>
    </Modal>
  );
};
export default PopulateDeviceTypeChangeConfirmationModal;
