//eslint-disable-next-line
type CallbackHandler = (...args: any[]) => unknown;

class EventEmitterService {
  callbacks: Record<string, CallbackHandler[]>;

  constructor() {
    this.callbacks = {};
  }

  on(event: string, cb: CallbackHandler) {
    if (!this.callbacks[event]) {
      this.callbacks[event] = [];
    }
    this.callbacks[event].push(cb);
  }

  off(event: string) {
    this.callbacks[event] = [];
  }

  emit(event: string, data?: unknown) {
    if (!Array.isArray(this.callbacks[event])) {
      return;
    }
    this.callbacks[event].forEach((cb) => cb(data));
  }
}

export default EventEmitterService;
