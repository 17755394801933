import React, { useCallback, useState } from "react";

import { TableBodyCellWrapper } from "@src/components";
import { NonMappableCellValue } from "@src/hooks";

import styles from "@src/components/common/Table/Table.module.scss";

interface EditingTableBodyCellNumberProps {
  value: NonMappableCellValue;
  scope: [number, number];
  step: number;
  onDone: (v: NonMappableCellValue) => void;
}
function getNumericValueInScope(numericValue: number, scope: [number, number]): number {
  if (numericValue < scope[0]) {
    return scope[0];
  }
  if (numericValue > scope[1]) {
    return scope[1];
  }
  return numericValue;
}

function getNumericValue(inputValue: string | number, fallback?: number): number {
  if (inputValue === null || inputValue === undefined) {
    return fallback;
  }
  if (typeof inputValue === "number" && isNaN(inputValue)) {
    return fallback;
  }
  if (typeof inputValue === "number") {
    return inputValue;
  }
  const numericValue = parseFloat(inputValue);
  if (isNaN(numericValue)) {
    return fallback;
  }
  return numericValue;
}
function getValidNumber(inputValue: string | number, scope: [number, number]): number {
  const numericValue = getNumericValue(inputValue, scope[0]);
  return getNumericValueInScope(numericValue, scope);
}

function EditingTableBodyCellNumber({ value, scope, step, onDone }: EditingTableBodyCellNumberProps) {
  const [min, max] = scope;
  const [localState, setLocalState] = useState<string | number>(value as number);

  const handleChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    setLocalState(e.currentTarget.value);
  }, []);

  const handleBlur = useCallback(() => {
    const newValue = getValidNumber(localState, scope);
    onDone(newValue);
  }, [localState, onDone]);

  function getInputValue() {
    const numericValue = getNumericValue(localState);
    if (numericValue === undefined) {
      return "";
    }
    return numericValue;
  }

  return (
    <TableBodyCellWrapper>
      <input
        className={styles["table-body-cell-input"]}
        type="number"
        min={min}
        max={max}
        step={step}
        value={getInputValue()}
        onChange={handleChange}
        onBlur={handleBlur}
        autoFocus={true}
        data-testid="table-body-cell-input"
      />
    </TableBodyCellWrapper>
  );
}

export default EditingTableBodyCellNumber;
