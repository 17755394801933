import React from "react";
import { BrowserRouter } from "react-router-dom";

import { GlobalLayout, AuthProvider, ApiClientProvider } from "@src/components";
import { GlobalRoutes } from "@src/routes";

import "@src/assets/styles/index.scss";

// used in tests other than auth together with customRender that wraps it in appropriate providers
export function App() {
  return (
    <GlobalLayout>
      <GlobalRoutes />
    </GlobalLayout>
  );
}

// used in auth tests together with customAuthRender that wraps it in appropriate providers
export function AuthApp() {
  return (
    <ApiClientProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ApiClientProvider>
  );
}

// used in real browser render
export function BrowserAuthedApp() {
  return (
    <BrowserRouter>
      <AuthApp />
    </BrowserRouter>
  );
}

export default BrowserAuthedApp;
