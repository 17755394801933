import React from "react";
import classnames from "classnames";

import styles from "@src/components/common/Loader/Loader.module.scss";

interface LoaderType {
  color?: string;
}

const Loader: React.FC<LoaderType> = ({ color }) => {
  const getColor = () => {
    if (!color) {
      return "spinner-border text-primary";
    }
    return `spinner-border p-2 text-${color}`;
  };
  return (
    <div data-testid="loader" className="d-flex justify-content-center">
      <div className={classnames(getColor(), styles.loader)} role="status"></div>
    </div>
  );
};
export default Loader;
