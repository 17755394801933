import { MappedCellValue, Row } from "@src/hooks";

export enum OrderType {
  Exchange = "Exchange",
  Sales = "Sales",
  Upgrade = "Upgrade",
  DeviceUser = "Device User",
}

export enum RmaType {
  Exchange = "Exchange",
  In_Trial_Upgrade = "In-trial Upgrade",
  Upgrade = "Upgrade",
  Loss_Damage = "Loss & Damage",
  Out_Of_Warranty = "Out Of Warranty",
}

export interface OrderItemViewValue extends Row {
  id: number;
  salesforce_id: string;
  is_amazon: boolean;
  email: string;
  last_name: string;
  first_name: string;
  product_serial_number: string;
  product_code: MappedCellValue<string>;
  order_number: string;
  order_type: OrderType;
  rma_type: RmaType;
  status: string;
  original_order_number: string;
  processed: boolean;
  created_at: Date;
  updated_at: Date;
}

export interface OrderItemModelValue {
  id: number;
  salesforce_id: string;
  is_amazon: boolean;
  email: string;
  last_name: string;
  first_name: string;
  product_serial_number: string;
  product_code: string;
  order_number: string;
  order_type: OrderType;
  rma_type: RmaType;
  status: string;
  original_order_number: string;
  processed: boolean;
  created_at: Date;
  updated_at: Date;
}
