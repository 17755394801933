import React, { useCallback, useContext, useMemo, useState } from "react";

import {
  EditingTableBodyCellNumber,
  EditingTableBodyCellSelect,
  EditingTableBodyCellText,
  ReadOnlyTableBodyCell,
  TableContext,
} from "@src/components";

import { Column, ColumnType, NonMappableCellValue, Row } from "@src/hooks";

interface TableBodyCellProps {
  row: Row;
  column: Column;
  rowIndex: number;
}

function EditableTableBodyCell({ column, row, rowIndex }: TableBodyCellProps) {
  const { tableState, tableHandlers } = useContext(TableContext);
  const [isEditing, setIsEditing] = useState(false);

  const viewValue = useMemo(() => {
    if (column.type !== ColumnType.Select) {
      return row[column.key];
    }
    const option = column.options.find((option) => {
      return option.value === row[column.key];
    });
    if (!option) {
      return null;
    }
    return option.label;
  }, [row[column.key], column]);

  const originalValue = useMemo(() => {
    const originalRow = tableState.originalRows.find((originalRow) => {
      return tableHandlers.getRowId(originalRow) === tableHandlers.getRowId(row);
    });
    if (column.type !== ColumnType.Select) {
      return originalRow[column.key];
    }
    const option = column.options.find((option) => {
      return option.value === originalRow[column.key];
    });
    if (!option) {
      return null;
    }
    return option.label;
  }, [tableState.originalRows, tableHandlers.getRowId, column, row]);

  const handleEditMode = useCallback(() => setIsEditing(true), []);

  const handleApplyCellValue = useCallback(
    (value: NonMappableCellValue) => {
      tableHandlers.onEdit(column.key, rowIndex, value);
      setIsEditing(false);
    },
    [column.key, rowIndex, tableHandlers.onEdit]
  );

  if (!isEditing || column.type === ColumnType.NonEditable) {
    return (
      <ReadOnlyTableBodyCell
        column={column}
        value={viewValue}
        originalValue={originalValue}
        onEditMode={handleEditMode}
      />
    );
  }

  if (column.type === ColumnType.Text) {
    return <EditingTableBodyCellText value={viewValue as NonMappableCellValue} onDone={handleApplyCellValue} />;
  }

  if (column.type === ColumnType.Number) {
    return (
      <EditingTableBodyCellNumber
        value={viewValue as NonMappableCellValue}
        scope={column.scope}
        step={column.step}
        onDone={handleApplyCellValue}
      />
    );
  }

  if (column.type === ColumnType.Select) {
    return (
      <EditingTableBodyCellSelect
        options={column.options}
        value={row[column.key] as NonMappableCellValue}
        onDone={handleApplyCellValue}
      />
    );
  }

  return <div>Unknown column type</div>;
}

export default EditableTableBodyCell;
