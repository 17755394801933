import React, { useCallback, useState } from "react";

import { TableBodyCellWrapper } from "@src/components";
import { NonMappableCellValue } from "@src/hooks";

import styles from "@src/components/common/Table/Table.module.scss";

interface EditingTableBodyCellTextProps {
  value: NonMappableCellValue;
  onDone: (v: NonMappableCellValue) => void;
}

function EditingTableBodyCellText({ value, onDone }: EditingTableBodyCellTextProps) {
  const [localState, setLocalState] = useState(value);

  const handleChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    setLocalState(e.currentTarget.value);
  }, []);

  const handleBlur = useCallback(() => {
    onDone(localState);
  }, [localState, onDone]);

  function getInputValue() {
    if (localState === null || localState === undefined) {
      return "";
    }
    return localState.toString();
  }

  return (
    <TableBodyCellWrapper>
      <input
        className={styles["table-body-cell-input"]}
        type="text"
        value={getInputValue()}
        onChange={handleChange}
        onBlur={handleBlur}
        autoFocus={true}
        data-testid="table-body-cell-input"
      />
    </TableBodyCellWrapper>
  );
}

export default EditingTableBodyCellText;
