import { useCallback, useEffect } from "react";

import { OrderItemViewValue, OrdersApiClient } from "@src/services";
import { ExecutorFactory, RequestState, useApiClient, useAsync } from "@src/hooks";

// eslint-disable-next-line
interface AsyncAction<P extends any[], T> {
  state: RequestState<T>;
  execute: ExecutorFactory<P, T>;
}

function useOrders(
  salesforceId: string
): [AsyncAction<[], OrderItemViewValue[]>, AsyncAction<[OrderItemViewValue[]], void>] {
  const apiClient = useApiClient();

  useEffect(() => {
    OrdersApiClient.setup(apiClient);
  }, [apiClient]);

  const handleGetOrders = useCallback(async () => {
    const modelValues = await OrdersApiClient.getAll(salesforceId);
    return OrdersApiClient.modelValuesToViewValues(modelValues);
  }, [salesforceId]);

  const [orders, getOrders] = useAsync<[], OrderItemViewValue[]>(handleGetOrders);

  const handleApplyOrders = useCallback(
    async (orderItems: OrderItemViewValue[]) => {
      const modelValues = OrdersApiClient.viewValuesToModelValues(orderItems);
      await OrdersApiClient.applyChanges(salesforceId, modelValues);
      await getOrders();
    },
    [salesforceId]
  );

  const [applyingState, applyOrders] = useAsync<[OrderItemViewValue[]], void>(handleApplyOrders);

  useEffect(() => {
    getOrders();
  }, []);

  return [
    { state: orders, execute: getOrders },
    { state: applyingState, execute: applyOrders },
  ];
}

export default useOrders;
