export enum RequestStatus {
  idle = "idle",
  pending = "pending",
  success = "success",
  error = "error",
}

export interface RequestState<T> {
  status: RequestStatus;
  value: T | null;
  error: unknown;
}

// eslint-disable-next-line
export type ExecutorFactory<P extends any[], T> = (...args: P) => Promise<T>;

// eslint-disable-next-line
export type UseAsyncReturnType<P extends any[] | [], T> = [RequestState<T>, ExecutorFactory<P, T>];
