import { useCallback, useEffect } from "react";

import { ExecutorFactory, RequestState, useApiClient, useAsync } from "@src/hooks";

import { ManufacturerItemViewValue, ManufacturersDataApiClient } from "@src/services";

// eslint-disable-next-line
interface AsyncAction<P extends any[], T> {
  state: RequestState<T>;
  execute: ExecutorFactory<P, T>;
}

function useManufacturersData(
  salesforceId: string
): [AsyncAction<[], ManufacturerItemViewValue[]>, AsyncAction<[ManufacturerItemViewValue[]], void>] {
  const apiClient = useApiClient();

  useEffect(() => {
    ManufacturersDataApiClient.setup(apiClient);
  }, [apiClient]);

  const handleGetManufacturersData = useCallback(async () => {
    const modelValues = await ManufacturersDataApiClient.getAll(salesforceId);
    return ManufacturersDataApiClient.modelValuesToViewValues(modelValues);
  }, [salesforceId]);

  const [manufacturersData, getManufacturersData] = useAsync<[], ManufacturerItemViewValue[]>(
    handleGetManufacturersData
  );

  const handleApplyManufacturesData = useCallback(
    async (manufacturersDataItems: ManufacturerItemViewValue[]) => {
      const modelValues = ManufacturersDataApiClient.viewValuesToModelValues(manufacturersDataItems);
      await ManufacturersDataApiClient.applyChanges(salesforceId, modelValues);
      await getManufacturersData();
    },
    [salesforceId]
  );

  const [applyingState, applyManufacturersData] = useAsync<[ManufacturerItemViewValue[]], void>(
    handleApplyManufacturesData
  );

  useEffect(() => {
    getManufacturersData();
  }, []);

  return [
    { state: manufacturersData, execute: getManufacturersData },
    { state: applyingState, execute: applyManufacturersData },
  ];
}

export default useManufacturersData;
