import React, { HTMLProps } from "react";
import classnames from "classnames";

import styles from "@src/components/common/Table/Table.module.scss";

interface TableBodyCellWrapperProps extends HTMLProps<HTMLDivElement> {
  children: React.ReactNode;
}

function TableBodyCellWrapper({ children, className, ...rest }: TableBodyCellWrapperProps) {
  return (
    <div data-testid="table-body-cell" className={classnames(styles.cell, className)} {...rest}>
      {children}
    </div>
  );
}

export default TableBodyCellWrapper;
