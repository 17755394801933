import React from "react";
import classnames from "classnames";

import { TableBodyCellWrapper } from "@src/components";
import { CellValue, Column, ColumnType, MappedCellValue, NonMappableCellValue } from "@src/hooks";

import styles from "@src/components/common/Table/Table.module.scss";

interface ReadOnlyTableBodyCellProps {
  value: CellValue;
  originalValue: CellValue;
  column: Column;
  onEditMode: () => void;
}

function ReadOnlyTableBodyCell({ value, originalValue, column, onEditMode }: ReadOnlyTableBodyCellProps) {
  function isMappedValue(value: CellValue): value is MappedCellValue<NonMappableCellValue> {
    return (
      typeof value === "object" &&
      value !== null &&
      !(value instanceof Date) &&
      typeof value.displayValue !== "undefined"
    );
  }
  const getRenderableValue = (v: CellValue): string => {
    if (isMappedValue(v)) {
      return getRenderableValue(v.displayValue);
    }
    if (v === null) {
      return "null";
    }
    if (v === undefined) {
      return "undefined";
    }
    return v.toString();
  };
  return (
    <TableBodyCellWrapper
      className={classnames({
        [styles.edited]: value !== originalValue,
        [styles.editable]: column.type === ColumnType.NonEditable,
      })}
      onDoubleClick={onEditMode}
    >
      <div className={styles["table-body-cell-value"]} data-testid="table-body-cell-value">
        {getRenderableValue(value)}
      </div>
    </TableBodyCellWrapper>
  );
}

export default ReadOnlyTableBodyCell;
