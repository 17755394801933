import React, { HTMLProps } from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import styles from "@src/components/common/Button/Button.module.scss";

export enum ButtonType {
  Classic = "classic",
  Primary = "primary",
}

interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  text: string;
  icon?: IconProp;
  type: ButtonType;
}

const Button: React.FC<ButtonProps> = ({ text, icon, type, ...rest }) => {
  function renderIconIfPresent() {
    if (!icon) {
      return null;
    }
    return <FontAwesomeIcon icon={icon} />;
  }
  return (
    <button
      type="button"
      className={classnames(styles.button, {
        [styles.classic]: type === ButtonType.Classic,
        [styles.primary]: type === ButtonType.Primary,
      })}
      {...rest}
    >
      {renderIconIfPresent()}
      {text.toUpperCase()}
    </button>
  );
};

export default Button;
