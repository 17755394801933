import {
  ProductCodesService,
  ApiClientInterface,
  EargoGeneration,
  ManufacturerItemModelValue,
  ManufacturerItemViewValue,
  DeviceTypesService,
  EargoGenerationService,
  EargoGenerationHumanFriendly,
} from "@src/services";

class ManufacturersDataApiClient {
  static apiClient: ApiClientInterface;

  static SystemProductNumberMap = ProductCodesService.humanFriendlyValueMap;
  static DeviceTypesMap = DeviceTypesService.deviceTypesToHumanFriendlyValueMap;

  static setup(apiClient: ApiClientInterface) {
    ManufacturersDataApiClient.apiClient = apiClient;
  }

  static async getAll(salesforceId: string): Promise<ManufacturerItemModelValue[]> {
    if (!salesforceId) {
      throw new Error("Salesforce account id not present in parameters");
    }
    const response = await ManufacturersDataApiClient.apiClient.get<ManufacturerItemModelValue[]>(
      `/api/v1/admin/customers/${salesforceId}/pegatrondevices`
    );
    return response.data.data;
  }

  static async applyChanges(
    salesforceId: string,
    items: ManufacturerItemModelValue[]
  ): Promise<ManufacturerItemModelValue[]> {
    if (!salesforceId) {
      throw new Error("Salesforce account id not present in parameters");
    }
    const response = await ManufacturersDataApiClient.apiClient.patch<
      ManufacturerItemModelValue[],
      ManufacturerItemModelValue[]
    >(`/api/v1/admin/customers/${salesforceId}/pegatrondevices`, items);
    return response.data.data;
  }

  static modelValuesToViewValues(modelValues: ManufacturerItemModelValue[]): ManufacturerItemViewValue[] {
    return modelValues.map((modelValue) => {
      const systemProductNumberMapKey =
        modelValue.system_product_number as keyof typeof ManufacturersDataApiClient.SystemProductNumberMap;
      const deviceTypeMapKey = modelValue.device_type as keyof typeof ManufacturersDataApiClient.DeviceTypesMap;
      return {
        ...modelValue,
        system_product_number: {
          displayValue:
            ManufacturersDataApiClient.SystemProductNumberMap[systemProductNumberMapKey] ||
            modelValue.system_product_number,
          originalValue: modelValue.system_product_number,
        },
        device_type: {
          displayValue: ManufacturersDataApiClient.DeviceTypesMap[deviceTypeMapKey] || modelValue.device_type,
          originalValue: modelValue.device_type,
        },
        generation: EargoGenerationService.humanFriendlyValueMap[modelValue.generation],
      };
    });
  }

  static viewValuesToModelValues(viewValues: ManufacturerItemViewValue[]): ManufacturerItemModelValue[] {
    return viewValues.map((viewValue) => {
      const modelValueMappedGeneration =
        EargoGenerationService.originalGenerationValueMap[viewValue.generation as EargoGenerationHumanFriendly];
      const modelGeneration = modelValueMappedGeneration || (viewValue.generation as EargoGeneration);
      return {
        ...viewValue,
        system_product_number: viewValue.system_product_number.originalValue,
        device_type: viewValue.device_type.originalValue,
        generation: modelGeneration,
      };
    });
  }
}

export default ManufacturersDataApiClient;
