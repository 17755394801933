import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import _ from "lodash";

import { EventEmitterService, OAuthApiClient, AuthData, ApiResponse, ApiClientInterface } from "@src/services";

class EargoApiClient extends EventEmitterService implements ApiClientInterface {
  private authData: AuthData;
  private readonly axios: AxiosInstance;

  constructor(apiUrl: string) {
    super();
    this.axios = axios.create({ baseURL: apiUrl });
    this.axios.interceptors.response.use(undefined, (error) => {
      if (error.response.status === 401) {
        return this.tryRefreshing(error);
      }
      return Promise.reject(error);
    });
  }

  private async tryRefreshing(error: AxiosError) {
    if (!this.authData) {
      this.emit("logout");
      return Promise.reject(error);
    }
    const newAuthData = await OAuthApiClient.refreshAccess(this.authData?.refresh_token);
    this.setAuthData(newAuthData);
    return this.axios(error.request.config);
  }

  public setAuthData(authData: AuthData): void {
    this.authData = authData;
  }

  private getRequestConfig(config: AxiosRequestConfig): AxiosRequestConfig {
    if (!this.authData) {
      return config;
    }

    return _.extend(config, {
      headers: {
        Authorization: "Bearer " + this.authData.access_token,
      },
    });
  }

  public async get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<ApiResponse<T>>> {
    const outputConfig = this.getRequestConfig(config);
    return this.axios.get(url, outputConfig);
  }

  public async post<T, D>(url: string, data: D, config: AxiosRequestConfig): Promise<AxiosResponse<ApiResponse<T>>> {
    const outputConfig = this.getRequestConfig(config);
    return this.axios.post(url, data, outputConfig);
  }

  public async put<T, D>(url: string, data: D, config: AxiosRequestConfig): Promise<AxiosResponse<ApiResponse<T>>> {
    const outputConfig = this.getRequestConfig(config);
    return this.axios.put(url, data, outputConfig);
  }

  public async patch<T, D>(url: string, data: D, config: AxiosRequestConfig): Promise<AxiosResponse<ApiResponse<T>>> {
    const outputConfig = this.getRequestConfig(config);
    return this.axios.patch(url, data, outputConfig);
  }
}

export default EargoApiClient;
