import { ProductCodes } from "@src/services/ProductCodesService/types";

class ProductCodesService {
  static humanFriendlyValueMap: Record<ProductCodes, string> = {
    [ProductCodes.Eargo5FullSystem]: "Eargo 5 Full System",
    "04-0141-001": "Eargo 5 Left HI",
    "04-0141-002": "Eargo 5 Right HI",
    "04-0272": "Eargo 5 Charger",
    "04-0156": "Eargo 5 Charger",
    "99-0126-001": "Eargo 5 Left HI",
    "99-0127-001": "Eargo 5 Right HI",
    "99-0147-001": "Eargo 6 Full System",
    "04-0290-001": "Eargo 6 Left HI",
    "04-0290-002": "Eargo 6 Right HI",
    "99-0149-001": "Eargo 6 Left HI",
    "99-0150-001": "Eargo 6 Right HI",
    "99-0156-101": "Eargo 7 Full System",
    "04-0313-001": "Eargo 7 Left HI",
    "04-0313-002": "Eargo 7 Right HI",
    "99-0157-001": "Eargo 7 Left HI",
    "99-0158-001": "Eargo 7 Right HI",
    "04-0270": "Universal Charger",
    "99-0128-001": "Universal Charger",
    "99-0141-001": "Universal Charger",
    "99-0146-001": "Universal Charger",
  };
}

export default ProductCodesService;
