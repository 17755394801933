import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useAuth } from "@src/hooks";

import { Loader } from "@src/components";

const LogoutPage = () => {
  const { handleLogout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    handleLogout();
    navigate(`/oauth/login${location.search}`);
  }, []);

  return <Loader />;
};

export default LogoutPage;
