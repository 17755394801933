import { MappedCellValue, Row } from "@src/hooks";

import { EargoDeviceType } from "@src/services";

export enum DeviceStatusType {
  Shipped = "SHIPPED",
  Delivered = "DELIVERED",
  Deactivated = "DEACTIVATED",
}

export enum DeviceOriginType {
  New = "new",
  Rma = "rma",
}

export interface DeviceItemViewValue extends Row {
  id: number;
  serial_number: string;
  device_type: MappedCellValue<EargoDeviceType>;
  created_at: Date;
  updated_at: Date;
  system_serial_number: string;
  firmware_version: string;
  device_origin: DeviceOriginType | null;
  status: DeviceStatusType;
  name: string;
  ble_name: string;
  receiver_calibration: number;
  is_semi_deactivated: boolean;
}

export interface DeviceItemModelValue {
  id: number;
  serial_number: string;
  device_type: EargoDeviceType;
  created_at: Date;
  updated_at: Date;
  system_serial_number: string;
  firmware_version: string;
  device_origin: DeviceOriginType | null;
  status: DeviceStatusType;
  name: string;
  ble_name: string;
  receiver_calibration: number;
  is_semi_deactivated: boolean;
}
