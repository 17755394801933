import React from "react";

import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { OAuthRoutes, PrivateRoutes } from "@src/routes";

const GlobalRoutes = () => {
  const location = useLocation();
  return (
    <Routes>
      <Route index element={<Navigate to={`/oauth${location.search}`} replace />} />
      <Route path="/oauth/*" element={<OAuthRoutes />} />
      <Route path="/private/*" element={<PrivateRoutes />} />
      <Route path="*" element={<Navigate to={`/${location.search}`} replace />} />
    </Routes>
  );
};

export default GlobalRoutes;
