import React, { useCallback, useState, useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import axios from "axios";

import { OAuthApiClient } from "@src/services";

import { Login } from "@src/components";

import { useAuth } from "@src/hooks";

const LoginPage = () => {
  const [searchParams] = useSearchParams();
  const { isLogged, loggingInState, handleLoginError, handleLoginInit, handleLoginWithCode } = useAuth();
  const [externalPopup, setExternalPopup] = useState(null);

  const handleMessage = useCallback(
    async (event: MessageEvent<{ code: string; messageType: string }>) => {
      try {
        if (event.origin !== window.location.origin || event.data?.messageType !== "authorization_code") {
          return;
        }
        externalPopup?.close();
        setExternalPopup(null);
        await handleLoginWithCode(event.data.code);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          return handleLoginError(error.message);
        }
        handleLoginError(error);
      }
    },
    [externalPopup, handleLoginError, handleLoginWithCode]
  );

  const handleLogin = useCallback(async () => {
    try {
      handleLoginInit();

      const url = await OAuthApiClient.getAuthorizeUrl();
      const width = 400;
      const height = 600;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2.5;
      const popup = window.open(url, `Salesforce Auth`, `width=${width},height=${height},left=${left},top=${top}`);
      setExternalPopup(popup);
    } catch (error) {
      handleLoginError(error);
    }
  }, [handleLoginError, handleLoginInit]);

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [handleMessage]);

  if (!isLogged) {
    return <Login loggingInState={loggingInState} disabled={loggingInState.loading} handleLogin={handleLogin} />;
  }

  const redirectPath = searchParams.get("redirect");
  if (!redirectPath) {
    return <Navigate to={`/private/?${searchParams.toString()}`} replace />;
  }

  searchParams.delete("redirect");
  return <Navigate to={redirectPath} replace />;
};

export default LoginPage;
