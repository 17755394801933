import React from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

import { SalesforceRoutes, SupportRoutes } from "@src/routes";
import { AuthGuard } from "@src/components";

const PrivateRoutes = () => {
  const location = useLocation();
  return (
    <AuthGuard>
      <Routes>
        <Route path="salesforce/*" element={<SalesforceRoutes />} />
        <Route path="support/*" element={<SupportRoutes />} />
        <Route index element={<Navigate to={`salesforce${location.search}`} replace />} />
      </Routes>
    </AuthGuard>
  );
};

export default PrivateRoutes;
