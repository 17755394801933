import React, { useCallback, useMemo, useState } from "react";

import { PopulateDeviceTypeChangeConfirmationModal, TableConsumer, TableContext } from "@src/components";

import { DeviceItemViewValue, DeviceStatusType } from "@src/services";

import {
  Column,
  EditHandler,
  IncomingApplyHandler,
  RefreshHandler,
  RequestState,
  TableSortingState,
  useTable,
} from "@src/hooks";

interface DevicesProps {
  columns: Column[];
  initialSortingState: TableSortingState;
  gettingDevicesState: RequestState<DeviceItemViewValue[]>;
  applyingDevicesState: RequestState<void>;
  onApply: IncomingApplyHandler;
  onRefresh: RefreshHandler;
  getItemId: (item: DeviceItemViewValue) => string;
}

const Devices: React.FC<DevicesProps> = ({
  gettingDevicesState,
  initialSortingState,
  getItemId,
  columns,
  onApply,
  onRefresh,
}) => {
  const [tableState, tableHandlers] = useTable({
    initialSortingState,
    rows: gettingDevicesState.value,
    columns,
    onRefresh,
    onApply,
    getRowId: getItemId,
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [deliveredDevice, setDeliveredDevice] = useState(null);

  const handleDeactivateOtherDevices = useCallback(
    (device: DeviceItemViewValue) => {
      tableState.rows.forEach((iteratingDevice: DeviceItemViewValue, index: number) => {
        if (
          getItemId(device) !== getItemId(iteratingDevice) &&
          iteratingDevice.device_type.originalValue === device.device_type.originalValue &&
          iteratingDevice.system_serial_number === device.system_serial_number
        ) {
          tableHandlers.onEdit("status", index, DeviceStatusType.Deactivated);
        }
      });
    },
    [tableState, tableHandlers, getItemId]
  );

  const handleConfirm = useCallback(() => {
    handleDeactivateOtherDevices(deliveredDevice);
    setIsOpen(false);
    setDeliveredDevice(null);
  }, [handleDeactivateOtherDevices, deliveredDevice]);

  const handleDeny = () => {
    setIsOpen(false);
    setDeliveredDevice(null);
  };

  const handleDeviceTableCellEdit: EditHandler = useCallback(
    (columnKey, rowIndex, value) => {
      tableHandlers.onEdit(columnKey, rowIndex, value);
      if (columnKey !== "status" || value !== DeviceStatusType.Delivered) {
        return;
      }
      setIsOpen(true);
      setDeliveredDevice(tableState.rows[rowIndex]);
    },
    [tableHandlers]
  );

  const modifiedTableHandlers = useMemo(
    () => ({
      ...tableHandlers,
      onEdit: handleDeviceTableCellEdit,
    }),
    [tableHandlers, handleDeviceTableCellEdit]
  );

  return (
    <>
      <PopulateDeviceTypeChangeConfirmationModal
        device={deliveredDevice}
        isOpen={isOpen}
        onConfirm={handleConfirm}
        onDeny={handleDeny}
      />

      <TableContext.Provider value={{ tableState, tableHandlers: modifiedTableHandlers }}>
        <TableConsumer id="devices" />
      </TableContext.Provider>
    </>
  );
};

export default Devices;
