import { useCallback, useEffect, useState } from "react";

import {
  CellValue,
  MappedCellValue,
  NonMappableCellValue,
  Row,
  TableHandlers,
  TableSettings,
  TableSortingState,
  TableState,
  UseTableReturnType,
} from "@src/hooks";

import { TableSortingDirection } from "@src/hooks/common/useTable.types";

const targetDirectionDictionary = {
  [TableSortingDirection.ASC]: TableSortingDirection.DSC,
  [TableSortingDirection.DSC]: TableSortingDirection.ASC,
};

function useTable({
  columns,
  rows,
  initialSortingState = {},
  onRefresh,
  onApply,
  onCellEdit,
  getRowId,
}: TableSettings): UseTableReturnType {
  // empty object indicates unsorted state
  const [sortingState, setSortingState] = useState<TableSortingState>(initialSortingState);
  const [localStateRows, setRows] = useState<Row[]>(rows);

  const handleReset = useCallback(() => {
    setRows(rows);
    setSortingState({});
  }, [rows]);

  const handleRefresh = useCallback(async () => {
    setSortingState({});
    return onRefresh();
  }, [onRefresh]);

  const handleApply = useCallback(async () => {
    return onApply(localStateRows);
  }, [onApply, localStateRows]);

  const handleEdit = useCallback(
    (columnKey: string, rowIndex: number, value: CellValue) => {
      if (typeof onCellEdit === "function") {
        onCellEdit(columnKey, rowIndex, value);
      }

      setRows((prevRows) =>
        prevRows.map((row, index) => {
          if (index !== rowIndex) {
            return row;
          }
          return {
            ...row,
            [columnKey]: value,
          };
        })
      );

      if (localStateRows[rowIndex][columnKey] === value) {
        return;
      }

      setSortingState((prevSortingState) => {
        if (prevSortingState.columnKey !== columnKey) {
          return prevSortingState;
        }
        return {};
      });
    },
    [localStateRows, onCellEdit]
  );

  const handleSort = useCallback((columnKey: string) => {
    setSortingState((prevState) => {
      if (prevState.columnKey === columnKey) {
        return {
          direction: targetDirectionDictionary[prevState.direction],
          columnKey,
        };
      }

      return {
        direction: TableSortingDirection.ASC,
        columnKey,
      };
    });
  }, []);

  useEffect(() => {
    const isMappedValue = (value: CellValue): value is MappedCellValue<NonMappableCellValue> => {
      return (
        typeof value === "object" &&
        value !== null &&
        !(value instanceof Date) &&
        typeof value.displayValue !== "undefined"
      );
    };

    const getDisplayValue = (value: CellValue): NonMappableCellValue => {
      if (!isMappedValue(value)) {
        return value;
      }
      return value.displayValue;
    };

    if (!sortingState.columnKey) {
      return;
    }
    setRows((prevRows) => {
      return prevRows.slice().sort((a, b) => {
        const valueA = getDisplayValue(a[sortingState.columnKey]);
        const valueB = getDisplayValue(b[sortingState.columnKey]);

        const sortDirectionASC = sortingState.direction === TableSortingDirection.ASC;
        const sortDirectionDSC = sortingState.direction === TableSortingDirection.DSC;

        if ((valueA > valueB && sortDirectionASC) || (valueA < valueB && sortDirectionDSC)) {
          return 1;
        }

        if ((valueA < valueB && sortDirectionASC) || (valueA > valueB && sortDirectionDSC)) {
          return -1;
        }

        return 0;
      });
    });
  }, [sortingState]);

  // keep original value as a source of truth for local state
  useEffect(() => {
    setRows(rows);
  }, [rows]);

  const tableHandlers: TableHandlers = {
    onReset: handleReset,
    onApply: handleApply,
    onRefresh: handleRefresh,
    onEdit: handleEdit,
    onSort: handleSort,
    getRowId: getRowId,
  };
  const tableState: TableState = {
    rows: localStateRows,
    originalRows: rows,
    columns,
    sortingState,
  };
  return [tableState, tableHandlers];
}

export default useTable;
