import React, { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { Column, ColumnType, TableSortingDirection, TableSortingState, useUsers } from "@src/hooks";

import { UserItemViewValue } from "@src/services";

import { Users } from "@src/components";

const UsersColumns: Column[] = [
  {
    label: "Id",
    key: "id",
    type: ColumnType.NonEditable,
  },
  {
    label: "Customer Id",
    key: "customer_id",
    type: ColumnType.NonEditable,
  },
  {
    label: "Eus Address",
    key: "eus_address",
    type: ColumnType.Number,
    scope: [0, 7],
    step: 1,
  },
  {
    label: "Generation",
    key: "generation",
    type: ColumnType.NonEditable,
  },
  {
    label: "Creation Date",
    key: "created_at",
    type: ColumnType.NonEditable,
  },
  {
    label: "Last Update Date",
    key: "updated_at",
    type: ColumnType.NonEditable,
  },
];

const initialSortingState: TableSortingState = {
  columnKey: "generation",
  direction: TableSortingDirection.ASC,
};

const UsersPage = () => {
  const [searchParams] = useSearchParams();
  const [fetching, applying] = useUsers(searchParams.get("accountId"));

  const getUsersItemId = useCallback((item: UserItemViewValue) => item.id, []);

  return (
    <Users
      columns={UsersColumns}
      initialSortingState={initialSortingState}
      gettingUsersState={fetching.state}
      applyingUsersState={applying.state}
      onApply={applying.execute}
      onRefresh={fetching.execute}
      getItemId={getUsersItemId}
    />
  );
};

export default UsersPage;
