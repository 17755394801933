import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDownWideShort, faArrowUpShortWide, faPen, faSort } from "@fortawesome/free-solid-svg-icons";

import { EditableTableBodyCell, TableContext } from "@src/components";

import { Column, ColumnType, TableSortingDirection } from "@src/hooks";

import styles from "@src/components/common/Table/Table.module.scss";

interface TableColumnProps {
  column: Column;
}

function TableColumn({ column }: TableColumnProps) {
  const { tableState, tableHandlers } = useContext(TableContext);

  const renderSortIconValue = () => {
    if (tableState.sortingState.columnKey !== column.key) {
      return <FontAwesomeIcon className="opacity-25" icon={faSort} title="unsorted" />;
    }
    if (tableState.sortingState.direction === TableSortingDirection.DSC) {
      return <FontAwesomeIcon icon={faArrowDownWideShort} title="descending" />;
    }
    if (tableState.sortingState.direction === TableSortingDirection.ASC) {
      return <FontAwesomeIcon icon={faArrowUpShortWide} title="ascending" />;
    }
  };

  const renderEditIconIfColumnEditable = () => {
    if (column.type === ColumnType.NonEditable) {
      return null;
    }
    return <FontAwesomeIcon icon={faPen} title="editable" />;
  };

  return (
    <div className={styles.column} data-testid="table-column">
      <div className={styles.header} onClick={() => tableHandlers.onSort(column.key)} data-testid="table-header-column">
        <div className={styles.cell}>
          <div className={styles["sort-icon"]} data-testid="sort-icon">
            {renderSortIconValue()}
          </div>
          <div data-testid="label">{column.label}</div>
          <div className="d-flex flex-end align-items-center opacity-25" data-testid="editable-icon">
            {renderEditIconIfColumnEditable()}
          </div>
        </div>
      </div>
      <div className={styles.body} data-testid="table-body-column">
        {tableState.rows.map((row, index) => (
          <EditableTableBodyCell key={index} rowIndex={index} row={row} column={column} />
        ))}
      </div>
    </div>
  );
}

export default TableColumn;
