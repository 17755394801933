import { ApiClientInterface, DeviceItemViewValue, DeviceItemModelValue, DeviceTypesService } from "@src/services";

class DevicesApiClient {
  static apiClient: ApiClientInterface;

  static DeviceTypeMap = DeviceTypesService.deviceTypesToHumanFriendlyValueMap;

  static setup(apiClient: ApiClientInterface) {
    DevicesApiClient.apiClient = apiClient;
  }

  static async getAll(salesforceId: string): Promise<DeviceItemModelValue[]> {
    if (!salesforceId) {
      throw new Error("Salesforce account id not present in parameters");
    }
    const response = await DevicesApiClient.apiClient.get<DeviceItemModelValue[]>(
      `/api/v1/admin/customers/${salesforceId}/devices`
    );
    return response.data.data;
  }

  static async applyChanges(salesforceId: string, items: DeviceItemModelValue[]): Promise<DeviceItemModelValue[]> {
    if (!salesforceId) {
      throw new Error("Salesforce account id not present in parameters");
    }
    const response = await DevicesApiClient.apiClient.patch<DeviceItemModelValue[], DeviceItemModelValue[]>(
      `/api/v1/admin/customers/${salesforceId}/devices`,
      items
    );
    return response.data.data;
  }

  static modelValuesToViewValues(modelValues: DeviceItemModelValue[]): DeviceItemViewValue[] {
    return modelValues.map((modelValue) => {
      const deviceTypeMapKey = modelValue.device_type as keyof typeof DevicesApiClient.DeviceTypeMap;
      return {
        ...modelValue,
        device_type: {
          displayValue: DevicesApiClient.DeviceTypeMap[deviceTypeMapKey] || modelValue.device_type,
          originalValue: modelValue.device_type,
        },
      };
    });
  }

  static viewValuesToModelValues(viewValues: DeviceItemViewValue[]): DeviceItemModelValue[] {
    return viewValues.map((viewValue) => {
      return {
        ...viewValue,
        device_type: viewValue.device_type.originalValue,
      };
    });
  }
}

export default DevicesApiClient;
