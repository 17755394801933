import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { SalesforcePagesLayout } from "@src/components";

import { DevicesPage, ManufacturersDataPage, OrdersPage, UsersPage } from "@src/pages";

const SalesforceRoutes = () => {
  const location = useLocation();
  return (
    <>
      <SalesforcePagesLayout>
        <Routes>
          <Route path="orders" element={<OrdersPage />} />
          <Route path="manufacturers_data" element={<ManufacturersDataPage />} />
          <Route path="users" element={<UsersPage />} />
          <Route path="devices" element={<DevicesPage />} />
          <Route path="*" element={<Navigate to={`orders${location.search}`} replace />} />
        </Routes>
      </SalesforcePagesLayout>
    </>
  );
};

export default SalesforceRoutes;
