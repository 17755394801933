import React, { useState, useMemo, useEffect, useCallback, createContext } from "react";
import axios, { AxiosError } from "axios";

import { AuthService, OAuthApiClient, AuthData } from "@src/services";

import { useApiClient } from "@src/hooks";

interface Props {
  children: React.ReactNode;
}

export interface AuthContextType {
  isLogged: boolean;
  loggingInState: { loading: boolean; success: boolean; error: AxiosError | Error };
  handleLoginWithCode: (code: string) => Promise<void>;
  handleLogout: () => void;
  handleLoginError: (error: AxiosError | Error | string) => void;
  handleLoginInit: () => void;
}
const defaultAuthValue: AuthContextType = {
  isLogged: false,
  loggingInState: { loading: false, success: false, error: null },
  handleLoginWithCode: () => Promise.resolve(),
  handleLogout: () => undefined,
  handleLoginError: () => undefined,
  handleLoginInit: () => undefined,
};

export const AuthContext = createContext<AuthContextType>(defaultAuthValue);

const AuthProvider = ({ children }: Props) => {
  const apiClient = useApiClient();
  const [loggingInState, setLoggingInState] = useState({
    loading: false,
    success: false,
    error: null,
  });

  const isLogged = useMemo(() => loggingInState.success, [loggingInState.success]);

  // base handlers - handleLoginInit, handleLoginSuccess, handleLoginError, handleLogout
  // provider consumers and internal provider methods should only use those handlers.
  const handleLoginInit = useCallback(() => {
    setLoggingInState({ loading: true, success: false, error: null });
  }, []);

  const handleLoginSuccess = useCallback(
    (authData: AuthData) => {
      // AuthService extends auth data, (refresh token missing in the refresh response)
      // this is why we need to use extended version of the authData
      const newData = AuthService.saveAuth(authData);
      apiClient.setAuthData(newData);
      setLoggingInState({ loading: false, success: true, error: null });
    },
    [apiClient]
  );

  const handleLoginError = useCallback(
    (error: unknown | AxiosError) => {
      apiClient.setAuthData(null);
      AuthService.removeAuth();
      setLoggingInState({ loading: false, success: false, error: error });
    },
    [apiClient]
  );

  const handleLogout = useCallback(() => {
    apiClient.setAuthData(null);
    AuthService.removeAuth();
    setLoggingInState({ loading: false, success: false, error: null });
  }, [apiClient]);

  const handleLoginWithCode = useCallback(
    async (code: string) => {
      try {
        handleLoginInit();
        const data = await OAuthApiClient.callback(code);
        await OAuthApiClient.verifyAccessToken(data.access_token);
        handleLoginSuccess(data);
      } catch (error) {
        handleLoginError(error);
      }
    },
    [handleLoginSuccess, handleLoginError, handleLoginInit]
  );

  // login from saved auth
  useEffect(() => {
    if (isLogged) {
      return;
    }
    const existingData = AuthService.getAuth();
    if (!existingData) {
      return;
    }
    const verifyAndHandleExistingAuth = async () => {
      try {
        handleLoginInit();
        await OAuthApiClient.verifyAccessToken(existingData.access_token);
        handleLoginSuccess(existingData);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response.status === 401) {
          return handleLoginError(new Error("invalid_session"));
        }
        handleLoginError(error);
      }
    };

    verifyAndHandleExistingAuth();
  }, []);

  // logout and refresh token listeners
  useEffect(() => {
    apiClient.on("logout", handleLogout);
    apiClient.on("token_refreshed", handleLoginSuccess);
    return () => {
      apiClient.off("logout");
      apiClient.off("token_refreshed");
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLogged,
        loggingInState,
        handleLoginError,
        handleLoginInit,
        handleLogout,
        handleLoginWithCode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
