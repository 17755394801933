import React, { HTMLProps } from "react";

import { RetryButton } from "@src/components";

interface RetriableWrapperProps extends HTMLProps<HTMLDivElement> {
  onRetry: () => unknown;
}

const RetriableWrapper: React.FC<RetriableWrapperProps> = ({ children, onRetry, ...wrapperProps }) => {
  return (
    <div {...wrapperProps}>
      {children}
      <RetryButton onClick={onRetry} />
    </div>
  );
};

export default RetriableWrapper;
