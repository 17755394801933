import { ApiClientInterface, OrderItemViewValue, OrderItemModelValue, ProductCodesService } from "@src/services";

class OrdersApiClient {
  static apiClient: ApiClientInterface;

  static ProductCodesMap = ProductCodesService.humanFriendlyValueMap;

  static setup(apiClient: ApiClientInterface) {
    OrdersApiClient.apiClient = apiClient;
  }

  static async getAll(salesforceId: string): Promise<OrderItemModelValue[]> {
    if (!salesforceId) {
      throw new Error("Salesforce account id not present in parameters");
    }
    const response = await OrdersApiClient.apiClient.get<OrderItemModelValue[]>(
      `/api/v1/admin/customers/${salesforceId}/orders`
    );
    return response.data.data;
  }

  static async applyChanges(salesforceId: string, items: OrderItemModelValue[]): Promise<OrderItemModelValue[]> {
    if (!salesforceId) {
      throw new Error("Salesforce account id not present in parameters");
    }
    const response = await OrdersApiClient.apiClient.patch<OrderItemModelValue[], OrderItemModelValue[]>(
      `/api/v1/admin/customers/${salesforceId}/orders`,
      items
    );
    return response.data.data;
  }

  // note: smuggled assumption - only non-editable values are possible to map
  static modelValuesToViewValues(modelValues: OrderItemModelValue[]): OrderItemViewValue[] {
    return modelValues.map((modelOrder) => ({
      ...modelOrder,
      product_code: {
        displayValue:
          OrdersApiClient.ProductCodesMap[modelOrder.product_code as keyof typeof OrdersApiClient.ProductCodesMap] ||
          modelOrder.product_code,
        originalValue: modelOrder.product_code,
      },
    }));
  }

  // note: smuggled assumption - only non-editable values are possible to map
  static viewValuesToModelValues(viewValues: OrderItemViewValue[]): OrderItemModelValue[] {
    return viewValues.map((viewOrder) => ({
      ...viewOrder,
      product_code: viewOrder.product_code.originalValue,
    }));
  }
}

export default OrdersApiClient;
