import React, { createContext, useEffect, useState } from "react";

import appConfig from "appConfig";

import { EargoApiClient } from "@src/services";

interface Props {
  children: React.ReactNode;
}

export const ApiClientContext = createContext<EargoApiClient>(null);

function ApiClientProvider({ children }: Props) {
  const [apiClient, setApiClient] = useState(null);

  useEffect(() => {
    setApiClient(new EargoApiClient(appConfig.API_URL));
  }, []);

  function renderChildrenWhenReady() {
    if (!apiClient) {
      return null;
    }
    return children;
  }

  return <ApiClientContext.Provider value={apiClient}>{renderChildrenWhenReady()}</ApiClientContext.Provider>;
}

export default ApiClientProvider;
