import React from "react";

import styles from "@src/components/common/ErrorMessage/ErrorMessage.module.scss";

const ErrorMessage: React.FC<{ message: string }> = ({ message }) => {
  return (
    <div className={styles["error-wrapper"]} data-testid="error-message">
      {message}
    </div>
  );
};

export default ErrorMessage;
