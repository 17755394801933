export enum EargoGeneration {
  FIVE = "EARGO_FIVE",
  SIX = "EARGO_SIX",
  SEVEN = "EARGO_SEVEN",
}

export enum EargoGenerationHumanFriendly {
  FIVE = "Eargo 5",
  SIX = "Eargo 6",
  SEVEN = "Eargo 7",
}
