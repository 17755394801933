import React from "react";
import { AxiosError } from "axios";

import { ErrorMessage, Loader, RetriableWrapper, Table } from "@src/components";

import { UserItemViewValue } from "@src/services";

import {
  Column,
  IncomingApplyHandler,
  RefreshHandler,
  RequestState,
  RequestStatus,
  TableSortingState,
} from "@src/hooks";

interface UsersProps {
  columns: Column[];
  initialSortingState: TableSortingState;
  gettingUsersState: RequestState<UserItemViewValue[]>;
  applyingUsersState: RequestState<void>;
  onApply: IncomingApplyHandler;
  onRefresh: RefreshHandler;
  getItemId: (item: UserItemViewValue) => string;
}

const Users: React.FC<UsersProps> = ({
  gettingUsersState,
  initialSortingState,
  applyingUsersState,
  getItemId,
  columns,
  onApply,
  onRefresh,
}) => {
  if (gettingUsersState.status === RequestStatus.success && gettingUsersState.value.length === 0) {
    return (
      <RetriableWrapper onRetry={onRefresh}>
        <div className="text-center" data-testid="empty-list-message">
          Empty List
        </div>
      </RetriableWrapper>
    );
  }

  if (
    gettingUsersState.status === RequestStatus.pending ||
    gettingUsersState.status === RequestStatus.idle ||
    applyingUsersState.status === RequestStatus.pending
  ) {
    return <Loader />;
  }

  if (
    gettingUsersState.status === RequestStatus.error &&
    (gettingUsersState.error instanceof AxiosError || gettingUsersState.error instanceof Error)
  ) {
    return (
      <RetriableWrapper onRetry={onRefresh}>
        <ErrorMessage message={`Error: ${gettingUsersState.error.message}`} />
      </RetriableWrapper>
    );
  }

  if (gettingUsersState.status === RequestStatus.error) {
    return (
      <RetriableWrapper onRetry={onRefresh}>
        <ErrorMessage message={`Error: ${gettingUsersState.error.toString()}`} />
      </RetriableWrapper>
    );
  }

  return (
    <Table
      id="users"
      columns={columns}
      rows={gettingUsersState.value}
      initialSortingState={initialSortingState}
      onRefresh={onRefresh}
      onApply={onApply}
      getRowId={getItemId}
    />
  );
};

export default Users;
