import { useCallback, useEffect } from "react";

import { DeviceItemViewValue, DevicesApiClient } from "@src/services";
import { ExecutorFactory, RequestState, useApiClient, useAsync } from "@src/hooks";

// eslint-disable-next-line
interface AsyncAction<P extends any[], T> {
  state: RequestState<T>;
  execute: ExecutorFactory<P, T>;
}

function useDevices(
  salesforceId: string
): [AsyncAction<[], DeviceItemViewValue[]>, AsyncAction<[DeviceItemViewValue[]], void>] {
  const apiClient = useApiClient();

  useEffect(() => {
    DevicesApiClient.setup(apiClient);
  }, [apiClient]);

  const handleGetDevices = useCallback(async () => {
    // await getSystems();
    const modelValues = await DevicesApiClient.getAll(salesforceId);
    return DevicesApiClient.modelValuesToViewValues(modelValues);
  }, [salesforceId]);

  const [devices, getDevices] = useAsync<[], DeviceItemViewValue[]>(handleGetDevices, { value: [] });

  const handleApplyDevices = useCallback(
    async (deviceItems: DeviceItemViewValue[]) => {
      const modelValues = DevicesApiClient.viewValuesToModelValues(deviceItems);
      await DevicesApiClient.applyChanges(salesforceId, modelValues);
      await getDevices();
    },
    [salesforceId]
  );

  const [applyingState, applyDevices] = useAsync<[DeviceItemViewValue[]], void>(handleApplyDevices);

  useEffect(() => {
    getDevices();
  }, []);

  return [
    { state: devices, execute: getDevices },
    { state: applyingState, execute: applyDevices },
    // { state: systems, execute: getSystems },
  ];
}

export default useDevices;
