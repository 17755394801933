import React, { useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { RequestStatus, useApiClient, useAsync, useAuth } from "@src/hooks";

import { CustomersApiClient } from "@src/services";

import { Loader } from "@src/components";

import styles from "@src/components/common/GlobalLayout/GlobalLayout.module.scss";

type Props = {
  children: React.ReactNode;
};

const GlobalLayout = ({ children }: Props) => {
  const [searchParams] = useSearchParams();

  const apiClient = useApiClient();
  const auth = useAuth();

  useEffect(() => {
    CustomersApiClient.setup(apiClient);
  }, [apiClient]);

  const getCustomerFactory = useCallback((salesforceId: string) => {
    return CustomersApiClient.getBySalesforceId(salesforceId);
  }, []);
  const [gettingCustomerState, getCustomer] = useAsync(getCustomerFactory);

  useEffect(() => {
    const sfId = searchParams.get("accountId");
    if (!auth.isLogged || !sfId || gettingCustomerState.value?.sf_account_id === sfId) {
      return;
    }

    getCustomer(sfId);
  }, [searchParams.get("accountId"), gettingCustomerState.value, auth.isLogged]);

  const customerName = useMemo(() => {
    if (!gettingCustomerState.value) {
      return "";
    }
    return gettingCustomerState.value.first_name + " " + gettingCustomerState.value.last_name;
  }, [gettingCustomerState]);

  function renderCustomerNameIfExists() {
    if (gettingCustomerState.status === RequestStatus.pending) {
      return <Loader color="white" />;
    }
    if (gettingCustomerState.status === RequestStatus.error) {
      return "Error...";
    }
    return customerName;
  }

  return (
    <>
      <div className={styles.header}>
        <h3 data-testid="left-side-text" className={styles["left-side-text"]}>
          {renderCustomerNameIfExists()}
        </h3>
        <h3 className={styles.title}>Admin Tool</h3>
      </div>
      {children}
    </>
  );
};

export default GlobalLayout;
