import { ApiClientInterface, Customer } from "@src/services";

class CustomersApiClient {
  static apiClient: ApiClientInterface;

  static setup(apiClient: ApiClientInterface) {
    CustomersApiClient.apiClient = apiClient;
  }

  static async getBySalesforceId(salesforceId: string): Promise<Customer> {
    if (!salesforceId) {
      throw new Error("Salesforce account id not present in parameters");
    }
    const result = await CustomersApiClient.apiClient.get<Customer>("/api/v1/admin/customers/" + salesforceId);
    return result.data.data;
  }
}

export default CustomersApiClient;
