import axios, { AxiosResponse } from "axios";

import appConfig from "appConfig";

import { ApiResponse, ApiResponseStatus, AuthData } from "@src/services";

class OAuthApiClient {
  // this is a special api client that does not rely on general EargoApiClient
  // this is because we don't want the underlying requests to be intercepted with the refresh token mechanism (circular dependency)
  static apiClient = axios.create({ baseURL: appConfig.API_URL });

  static async callback(code: string): Promise<AuthData> {
    const { data } = await OAuthApiClient.apiClient.post<ApiResponse<AuthData>>(`/api/v1/oauth/callback`, {
      code,
      redirect_uri: appConfig.SF_OAUTH_REDIRECT_URI,
    });
    return data.data;
  }

  static async getAuthorizeUrl(): Promise<string> {
    const response = await OAuthApiClient.apiClient.get<AxiosResponse<string>>(`/api/v1/oauth/url`, {
      params: { redirect_uri: appConfig.SF_OAUTH_REDIRECT_URI },
    });
    return response.data.data;
  }

  static async verifyAccessToken(accessToken: string): Promise<boolean> {
    const response = await OAuthApiClient.apiClient.post<ApiResponse<undefined>>(
      `/api/v1/oauth/verify_admin`,
      undefined,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    return response.data.status === ApiResponseStatus.success;
  }

  static async refreshAccess(refreshToken: string): Promise<AuthData> {
    const response = await OAuthApiClient.apiClient.post<ApiResponse<AuthData>>(`/api/v1/oauth/refresh`, {
      refresh_token: refreshToken,
      redirect_uri: appConfig.SF_OAUTH_REDIRECT_URI,
    });
    return response.data.data;
  }
}

export default OAuthApiClient;
