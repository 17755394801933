import { LocalStorageService, AuthData } from "@src/services";

class AuthService {
  static saveAuth(data: AuthData) {
    const existing = AuthService.getAuth();
    if (!existing) {
      LocalStorageService.setItem("auth", JSON.stringify(data));
      return data;
    }
    const extended = { ...existing, ...data };
    LocalStorageService.setItem("auth", JSON.stringify(extended));
    return extended;
  }

  static getAuth(): AuthData | null {
    const existing = LocalStorageService.getItem("auth");
    if (existing) {
      return JSON.parse(existing);
    }
    return null;
  }

  static removeAuth() {
    LocalStorageService.removeItem("auth");
  }
}
export default AuthService;
