import { ApiClientInterface, ApiResponse } from "@src/services";
import { AxiosError, AxiosResponse } from "axios";

class MockApiClient implements ApiClientInterface {
  mockErrorResponseCode: number;
  mockErrorResponseMessage: string;
  mockResponseData: unknown;

  static delay = (time: number) => new Promise((resolve) => setTimeout(resolve, time));

  public setResponse(responseValue: unknown) {
    this.mockResponseData = responseValue;
  }

  public setErrorResponse(responseCode: number, responseMessage: string) {
    this.mockErrorResponseCode = responseCode;
    this.mockErrorResponseMessage = responseMessage;
  }

  public async get<T>() {
    await MockApiClient.delay(1);
    if (this.mockErrorResponseCode) {
      throw new AxiosError(this.mockErrorResponseMessage, this.mockErrorResponseCode.toString());
    }
    return Promise.resolve({
      data: { data: this.mockResponseData, status: "success" },
      status: 200,
      statusText: "OK",
      headers: {},
      text: "",
      config: {},
    } as AxiosResponse<ApiResponse<T>>);
  }

  public async post() {
    await MockApiClient.delay(500);
    if (this.mockErrorResponseCode) {
      throw new AxiosError("Error message", this.mockErrorResponseCode.toString());
    }
    return Promise.resolve({
      data: { data: null, status: "success" },
      status: 200,
      statusText: "OK",
      headers: {},
      text: "",
      config: {},
    } as AxiosResponse<ApiResponse<null>>);
  }

  public async put() {
    await MockApiClient.delay(500);
    if (this.mockErrorResponseCode) {
      throw new AxiosError("Error message", this.mockErrorResponseCode.toString());
    }
    return Promise.resolve({
      data: { data: null, status: "success" },
      status: 200,
      statusText: "OK",
      headers: {},
      text: "",
      config: {},
    } as AxiosResponse<ApiResponse<null>>);
  }

  public async patch() {
    await MockApiClient.delay(500);
    if (this.mockErrorResponseCode) {
      throw new AxiosError("Error message", this.mockErrorResponseCode.toString());
    }
    return Promise.resolve({
      data: { data: null, status: "success" },
      status: 200,
      statusText: "OK",
      headers: {},
      text: "",
      config: {},
    } as AxiosResponse<ApiResponse<null>>);
  }
}

export default MockApiClient;
