import { EargoDeviceType } from "@src/services/DeviceTypesService/types";

class DeviceTypesService {
  static deviceTypesToHumanFriendlyValueMap = {
    [EargoDeviceType.charger]: "Charger",
    [EargoDeviceType.hi_left]: "Left HI",
    [EargoDeviceType.hi_right]: "Right HI",
    [EargoDeviceType.system]: "System",
  };
}

export default DeviceTypesService;
