import React, { createContext } from "react";

import { TableConsumer } from "@src/components";
import {
  EditHandler,
  ExportedApplyHandler,
  RefreshHandler,
  ResetHandler,
  RowIdGetter,
  SortHandler,
  TableHandlers,
  TableSettings,
  TableState,
  useTable,
} from "@src/hooks";

interface TableProps extends TableSettings {
  id: string;
}

const defaultOnEdit: EditHandler = () => {
  return;
};
const defaultOnSort: SortHandler = () => {
  return;
};
const defaultOnApply: ExportedApplyHandler = async () => {
  return;
};
const defaultOnRefresh: RefreshHandler = async () => {
  return;
};
const defaultOnReset: ResetHandler = () => {
  return;
};

const defaultRowIdGetter: RowIdGetter = () => "";

export const TableContext = createContext<{ tableState: TableState; tableHandlers: TableHandlers }>({
  tableState: {
    rows: [],
    originalRows: [],
    sortingState: {},
    columns: [],
  },
  tableHandlers: {
    onEdit: defaultOnEdit,
    onSort: defaultOnSort,
    onApply: defaultOnApply,
    onRefresh: defaultOnRefresh,
    onReset: defaultOnReset,
    getRowId: defaultRowIdGetter,
  },
});

const Table: React.FC<TableProps> = ({
  id,
  rows,
  columns,
  initialSortingState,
  getRowId,
  onApply,
  onRefresh,
  onCellEdit,
}) => {
  const [tableState, tableHandlers] = useTable({
    columns,
    rows,
    initialSortingState,
    onRefresh,
    onApply,
    onCellEdit,
    getRowId,
  });
  return (
    <TableContext.Provider value={{ tableState, tableHandlers }}>
      <TableConsumer id={id} />
    </TableContext.Provider>
  );
};

export default Table;
