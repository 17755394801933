export enum ProductCodes {
  Eargo5FullSystem = "99-0114-001",
  Eargo5LeftHI = "04-0141-001",
  Eargo5RightHI = "04-0141-002",
  Eargo5Charger = "04-0272",
  Eargo5Charger2 = "04-0156",
  Eargo5LeftHI2 = "99-0126-001",
  Eargo5RightHI2 = "99-0127-001",
  Eargo6FullSystem = "99-0147-001",
  Eargo6LeftHI = "04-0290-001",
  Eargo6RightHI = "04-0290-002",
  Eargo6LeftHI2 = "99-0149-001",
  Eargo6RightHI2 = "99-0150-001",
  Eargo7FullSystem = "99-0156-101",
  Eargo7LeftHI = "04-0313-001",
  Eargo7RightHI = "04-0313-002",
  Eargo7LeftHI2 = "99-0157-001",
  Eargo7RightHI2 = "99-0158-001",
  UniversalCharger = "04-0270",
  UniversalCharger2 = "99-0128-001",
  UniversalCharger3 = "99-0141-001",
  UniversalCharger4 = "99-0146-001",
}
