import React, { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { Column, ColumnType, TableSortingDirection, TableSortingState, useManufacturersData } from "@src/hooks";

import { EargoGeneration, EargoGenerationService, ManufacturerItemViewValue } from "@src/services";
import { ManufacturersData } from "@src/components";

const manufacturersDataColumns: Column[] = [
  {
    label: "Serial Number",
    key: "serial_number",
    type: ColumnType.NonEditable,
  },
  {
    label: "System Serial Number",
    key: "system_serial_number",
    type: ColumnType.NonEditable,
  },
  {
    label: "Device Type",
    key: "device_type",
    type: ColumnType.NonEditable,
  },
  {
    label: "Generation",
    key: "generation",
    type: ColumnType.Select,
    options: [
      {
        label: EargoGenerationService.humanFriendlyValueMap[EargoGeneration.FIVE],
        value: EargoGenerationService.humanFriendlyValueMap[EargoGeneration.FIVE],
      },
      {
        label: EargoGenerationService.humanFriendlyValueMap[EargoGeneration.SIX],
        value: EargoGenerationService.humanFriendlyValueMap[EargoGeneration.SIX],
      },
      {
        label: EargoGenerationService.humanFriendlyValueMap[EargoGeneration.SEVEN],
        value: EargoGenerationService.humanFriendlyValueMap[EargoGeneration.SEVEN],
      },
    ],
  },
  {
    label: "Creation Date",
    key: "created_at",
    type: ColumnType.NonEditable,
  },
  {
    label: "Last Update Date",
    key: "updated_at",
    type: ColumnType.NonEditable,
  },
  {
    label: "System Product Number",
    key: "system_product_number",
    type: ColumnType.NonEditable,
  },
  {
    label: "Imported",
    key: "imported",
    type: ColumnType.NonEditable,
  },
  {
    label: "Margin",
    key: "margin",
    type: ColumnType.NonEditable,
  },
  {
    label: "Ship Date",
    key: "ship_date",
    type: ColumnType.NonEditable,
  },
  {
    label: "Invoice",
    key: "invoice",
    type: ColumnType.NonEditable,
  },
  {
    label: "PO Number",
    key: "ponumber",
    type: ColumnType.NonEditable,
  },
  {
    label: "Refurbish Date",
    key: "refurbished_at",
    type: ColumnType.NonEditable,
  },
];

const initialSortingState: TableSortingState = {
  columnKey: "system_serial_number",
  direction: TableSortingDirection.ASC,
};

const ManufacturersDataPage = () => {
  const [searchParams] = useSearchParams();
  const [fetching, applying] = useManufacturersData(searchParams.get("accountId"));

  const getManufacturersDataItemId = useCallback((item: ManufacturerItemViewValue) => item.id.toString(), []);

  return (
    <ManufacturersData
      columns={manufacturersDataColumns}
      initialSortingState={initialSortingState}
      gettingManufacturersDataState={fetching.state}
      applyingManufacturersDataState={applying.state}
      onApply={applying.execute}
      onRefresh={fetching.execute}
      getItemId={getManufacturersDataItemId}
    />
  );
};

export default ManufacturersDataPage;
