import React, { useContext } from "react";
import classnames from "classnames";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";

import { TableColumn, Button, TableContext } from "@src/components";
import { ButtonType } from "@src/components/common/Button/Button";

import styles from "@src/components/common/Table/Table.module.scss";

interface TableConsumerProps {
  id: string;
}

function TableConsumer({ id }: TableConsumerProps) {
  const { tableHandlers, tableState } = useContext(TableContext);
  return (
    <div className={styles["table-with-buttons"]} data-testid="table">
      <div className={classnames(styles["buttons-wrapper"], styles.top)}>
        <Button
          onClick={tableHandlers.onRefresh}
          text={"Refresh"}
          type={ButtonType.Classic}
          icon={faArrowsRotate}
          data-testid="refresh-button"
        />
      </div>

      <div className={styles.table} data-testid={id}>
        {tableState.columns.map((column, index) => (
          <TableColumn key={index} column={column} />
        ))}
      </div>

      <div className={classnames(styles["buttons-wrapper"], styles.bottom)}>
        <Button onClick={tableHandlers.onReset} text={"Reset"} type={ButtonType.Classic} data-testid="reset-button" />
        <Button onClick={tableHandlers.onApply} text={"Apply"} type={ButtonType.Primary} data-testid="apply-button" />
      </div>
    </div>
  );
}

export default TableConsumer;
