import React from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

import { CallbackPage, LoginPage, LogoutPage } from "@src/pages";

const OAuthRoutes = () => {
  const location = useLocation();
  return (
    <Routes>
      <Route path="login" element={<LoginPage />} />
      <Route path="logout" element={<LogoutPage />} />
      <Route path="callback" element={<CallbackPage />} />
      <Route index element={<Navigate to={`/oauth/login${location.search}`} replace />} />
    </Routes>
  );
};

export default OAuthRoutes;
