import React, { useCallback, useMemo, useState } from "react";

import { TableBodyCellWrapper } from "@src/components";

import { NonMappableCellValue, SelectColumnOption } from "@src/hooks";

import styles from "@src/components/common/Table/Table.module.scss";

interface EditingTableBodyCellSelectProps {
  value: NonMappableCellValue;
  options: SelectColumnOption[];
  onDone: (v: NonMappableCellValue) => void;
}

function EditingTableBodyCellSelect({ value, options, onDone }: EditingTableBodyCellSelectProps) {
  const currentOption = useMemo(() => options.find((o) => o.value === value), [options, value]);
  const [localState, setLocalState] = useState(() => {
    if (!currentOption) {
      return value;
    }
    return currentOption.value;
  });

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLSelectElement>) => {
      if (localState instanceof Date) {
        return setLocalState(new Date(e.currentTarget.value));
      }
      if (typeof localState === "boolean") {
        return setLocalState(e.currentTarget.value === "true");
      }
      if (typeof localState === "number") {
        return setLocalState(parseFloat(e.currentTarget.value));
      }
      if (e.currentTarget.value === "null") {
        return setLocalState(null);
      }
      return setLocalState(e.currentTarget.value);
    },
    [localState]
  );

  const handleBlur = useCallback(() => {
    const option = options.find((o) => o.value === localState);
    if (!option) {
      // keep original
      return onDone(value);
    }
    onDone(option.value);
  }, [localState, onDone]);

  function getInputValue() {
    if (localState === null || localState === undefined) {
      return "null";
    }
    return localState.toString();
  }

  function getOptionValue(o: SelectColumnOption) {
    if (o.value === null) {
      return "null";
    }
    return o.value.toString();
  }

  return (
    <TableBodyCellWrapper>
      <select
        className={styles["table-body-cell-input"]}
        onChange={handleChange}
        onBlur={handleBlur}
        defaultValue={getInputValue()}
        autoFocus={true}
        data-testid="table-body-cell-input"
      >
        {options.map((option) => {
          const optionValue = getOptionValue(option);
          return (
            <option key={optionValue} value={optionValue}>
              {option.label}
            </option>
          );
        })}
      </select>
    </TableBodyCellWrapper>
  );
}

export default EditingTableBodyCellSelect;
