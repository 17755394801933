import React from "react";
import { AxiosError } from "axios";

import { ErrorMessage, Loader, RetriableWrapper, Table } from "@src/components";

import { ManufacturerItemViewValue } from "@src/services";

import {
  Column,
  IncomingApplyHandler,
  RefreshHandler,
  RequestState,
  RequestStatus,
  TableSortingState,
} from "@src/hooks";

interface ManufacturersDataProps {
  columns: Column[];
  initialSortingState: TableSortingState;
  gettingManufacturersDataState: RequestState<ManufacturerItemViewValue[]>;
  applyingManufacturersDataState: RequestState<void>;
  onApply: IncomingApplyHandler;
  onRefresh: RefreshHandler;
  getItemId: (item: ManufacturerItemViewValue) => string;
}

const ManufacturersData: React.FC<ManufacturersDataProps> = ({
  gettingManufacturersDataState,
  initialSortingState,
  applyingManufacturersDataState,
  getItemId,
  columns,
  onApply,
  onRefresh,
}) => {
  if (
    gettingManufacturersDataState.status === RequestStatus.success &&
    gettingManufacturersDataState.value.length === 0
  ) {
    return (
      <RetriableWrapper onRetry={onRefresh}>
        <div className="text-center" data-testid="empty-list-message">
          Empty List
        </div>
      </RetriableWrapper>
    );
  }

  if (
    gettingManufacturersDataState.status === RequestStatus.pending ||
    gettingManufacturersDataState.status === RequestStatus.idle ||
    applyingManufacturersDataState.status === RequestStatus.pending
  ) {
    return <Loader />;
  }

  if (
    gettingManufacturersDataState.status === RequestStatus.error &&
    (gettingManufacturersDataState.error instanceof AxiosError || gettingManufacturersDataState.error instanceof Error)
  ) {
    return (
      <RetriableWrapper onRetry={onRefresh}>
        <ErrorMessage message={`Error: ${gettingManufacturersDataState.error.message}`} />
      </RetriableWrapper>
    );
  }

  if (gettingManufacturersDataState.status === RequestStatus.error) {
    return (
      <RetriableWrapper onRetry={onRefresh}>
        <ErrorMessage message={`Error: ${gettingManufacturersDataState.error.toString()}`} />
      </RetriableWrapper>
    );
  }

  return (
    <Table
      id="manufacturers-data"
      columns={columns}
      rows={gettingManufacturersDataState.value}
      initialSortingState={initialSortingState}
      onRefresh={onRefresh}
      onApply={onApply}
      getRowId={getItemId}
    />
  );
};

export default ManufacturersData;
